import TitleBoard from '../../../components/items/TitleBoard';
import GameListItem from '../../../components/section5/GameListItem';

import { useCallback, useEffect, useState } from 'react';
import Cacao from '../../../assets/tree/cacao3.png';
import Carrot from '../../../assets/tree/carrot3.png';
import Coffee from '../../../assets/tree/coffee3.png';
import Corn from '../../../assets/tree/corn3.png';
import Cotton from '../../../assets/tree/cotton3.png';
import Jasmine from '../../../assets/tree/jasmine3.png';
import PeanutPlant from '../../../assets/tree/peanutplant3.png';
import Pepper from '../../../assets/tree/pepper3.png';
import PineTree from '../../../assets/tree/pinetree3.png';
import Potato from '../../../assets/tree/potato3.png';
import Rice from '../../../assets/tree/rice3.png';
import Rose from '../../../assets/tree/rose3.png';
import RubberTree from '../../../assets/tree/rubbertree3.png';
import Silk from '../../../assets/tree/silk3.png';
import Strawberry from '../../../assets/tree/strawberry3.png';
import Sugarcane from '../../../assets/tree/sugarcane3.png';
import teaplant from '../../../assets/tree/teaplant3.png';
import wheat from '../../../assets/tree/wheat3.png';
import IconPre from '../../../assets/section/section5/icon-pre.png';
import IconNext from '../../../assets/section/section5/icon-next.png';
import { CarouselItem } from 'react-bootstrap';

const ListImg = [
  Cacao,
  Carrot,
  Coffee,
  Corn,
  Cotton,
  Jasmine,
  PeanutPlant,
  Pepper,
  PineTree,
  Potato,
  Rice,
  Rose,
  RubberTree,
  Silk,
  Strawberry,
  Sugarcane,
  teaplant,
  wheat,
];

const title = 'NFT ITEM';

const Section5 = () => {
  const [currentImage, setCurrentImage] = useState(Cacao);
  const [page, setPage] = useState(0);
  const [countItem, setCountItem] = useState(6);
  const onChange = useCallback((current) => {
    setCurrentImage(current);
  }, []);

  useEffect(() => {
    const width = window.innerWidth;
    if (width < 768) {
      setCountItem(2);
    }
  }, []);
  return (
    <section
      className="section5 d-flex justify-content-center align-items-center"
      id="NFT_ITEM"
    >
      <div className="d-flex flex-column  align-items-center justify-content-center m-auto">
        <div className="section-5_title">
          <TitleBoard content={title} />
        </div>
        <div className="col-10 col-xl-7 section5-img_slideShow">
          <div className="section-5_image_wrapper d-flex flex-wrap justify-content-center">
            <img alt="" src={currentImage} className="img-fluid" />
            <img alt="" src={currentImage} className="img-fluid" />
            <img alt="" src={currentImage} className="img-fluid" />
          </div>{' '}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="section5-img_button"
            onClick={() => page > 0 && setPage((pre) => pre - 1)}
          >
            <img src={IconPre} alt="" className="img-fluid" />
          </div>
          <div className="d-flex flex-wrap">
            <GameListItem
              srcImg={[...ListImg].slice(page, page + countItem)}
              onChange={onChange}
            />
          </div>
          <div
            className="section5-img_button"
            onClick={() =>
              page + countItem < ListImg.length && setPage((pre) => pre + 1)
            }
          >
            <img src={IconNext} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Section5;
