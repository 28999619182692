import TopBackground from '../../components/footer/TopBackground';
import TitleBoard from '../../components/items/TitleBoard';
import FooterContent from '../../components/footer/FooterContent';
import Logo from '../../assets/footer/footer_logo.png';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { fadeUp } from '../../styles/animation/Animation';
import BottomImage from '../../assets/footer/footer_down-bg.png';
import ImageContract from '../../assets/footer/icon_contract.png';
import ImageTelegram from '../../assets/components/telegram.svg';
import ImageTwitter from '../../assets/components/twitter.svg';
const title = 'Tokenomics';

const FooterValue = [
  {
    title: 'Tokenmetric',
    content: [
      {
        name: 'Total Supply: ',
        value: '1,000,000 KFARM',
        isLine: true,
      },
      {
        name: 'Total Supply: ',
        value: '1,000,000 KFARM',
        isLine: true,
      },
      {
        name: 'FarmFi: ',
        value: '40%',
        isLine: true,
      },
      {
        name: 'Marketing: ',
        value: '3%',
        isLine: true,
      },
      {
        name: 'Marketing: ',
        value: '3%',
        isLine: false,
      },
    ],
  },
  {
    title: '',
    content: [
      {
        name: 'Cex: ',
        value: '5%',
        isLine: true,
      },
      {
        name: 'Presale: ',
        value: '45.4%',
        isLine: true,
      },
      {
        name: 'Liquidity: ',
        value: '4.5%',
        isLine: false,
      },
    ],
  },
  {
    title: 'Buy/Sell tax',
    content: [
      {
        name: 'Slip buy: ',
        value: '0%',
        isLine: true,
      },
      {
        name: 'Slip sell: ',
        value: '0%',
        isLine: false,
      },
    ],
  },
];

const Footer = () => {
  const ref = useInView();
  return (
    <motion.footer
      className="footer position-relative"
      id="Tokenomics"
      ref={ref}
      variants={fadeUp}
      initial="hidden"
      whileInView="visible"
    >
      <TopBackground />
      <div className="container d-flex gap-5 flex-column align-items-center justify-content-between mx-auto">
        <div className=" col-7 col-md-5 col-xl-4">
          <TitleBoard content={title} />
        </div>

        <div className=" row w-100 justify-content-between">
          {FooterValue.map((item, index) => {
            return (
              <FooterContent
                title={item.title}
                content={item.content}
                footerkey={index}
                key={index}
              />
            );
          })}
        </div>
      </div>
      <img src={BottomImage} alt="" className="img-fluid w-100" />
      <div className="footer_form_wrapper">
        <div className="container d-flex flex-column flex-sm-row justify-content-between gap-2 gap-xxl-5 w-100 p-xxl-5">
          <div className="footer_form d-flex flex-column gap-3">
            <div>
              <span className="footer-form_title">Join Our Mailing List</span>
              <p className="footer-form_text d-none d-sm-block">
                Be the first to hear all the lastest Farm Kingdom Game news
              </p>
            </div>

            <div className=" d-flex flex-column flex-sm-row gap-1 gap-sm-3 align-items-center ">
              <div className="d-flex gap-2">
                <div>
                  <input
                    type="text"
                    className=" p-1 p-sm-2 p-xxl-3 w-100"
                    placeholder="Name"
                    aria-label="First name"
                  />
                </div>
                <div className="">
                  <input
                    type="text"
                    className="p-1 p-sm-2 p-xxl-3 w-100"
                    placeholder="Email"
                    aria-label="Last name"
                  />
                </div>
              </div>

              <div className=" footer_button p-0 p-xxl-2">
                <button className="btn text-white" value="Subscribe">
                  Subscribe
                </button>
              </div>
            </div>
            <div className="d-flex gap-3 align-items-center mt-3">
              <div>
                <img src={ImageContract} alt="" />
              </div>
              <div className="text-ellipsis">
                Token contract:{' '}
                <a
                  className="text-green"
                  style={{ textDecoration: 'none' }}
                  href="https://testnet-gchainexplorer.genesys.network/address/0xcDB21F9638E3b64A01A65f2e402E1ea05652f4E6"
                  target='_blank' rel="noreferrer"
                >
                  0xcDB21F9638E3b64A01A65f2e402E1ea05652f4E6
                </a>
              </div>
            </div>
          </div>
          <div className="footer_logo d-flex flex-column gap-3 align-items-center">
            <img src={Logo} alt="" className=" logo img-fluid w-100 h-auto" />
            <div className=" d-flex flex-column align-items-center">
              <span className=" text-uppercase text-center">
                © 2024 By Farm Kingdom
              </span>
              <div>Build on Genesys Network #Blue20</div>
            </div>
            <div className="d-flex gap-3">
              <div className="">
                <img src={ImageTelegram} alt="" className="" />
              </div>
              <div>
                <img src={ImageTwitter} alt="" className="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.footer>
  );
};
export default Footer;
