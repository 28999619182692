import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { fadeUp } from '../../styles/animation/Animation';
import BG from '../../assets/section/section5/bg-item.png';
const GameItem = ({ srcImg, onChange }) => {
  const ref = useInView();
  return (
    <motion.div
      className="game_item "
      ref={ref}
      variants={fadeUp}
      initial="hidden"
      whileInView="visible"
      onClick={() => onChange(srcImg)}
    >
      <img
        src={BG}
        alt=""
        className="position-absolute top-0 start-0 img-fluid w-100 h-auto"
      />
      <img src={srcImg} alt="" className="section5-img w-100 h-auto" />
    </motion.div>
  );
};
export default GameItem;
